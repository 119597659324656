import React, { useEffect } from 'react';
// import { FormattedMessage } from 'react-intl';
import { Jumbotron } from 'reactstrap'
import withLayout from '../layout';
// import Link from '../components/Link';
// import Image from '../components/Image';
// import vista1 from '../images/vista1996.jpg'
// import vista2 from '../images/vista2019.jpg'



function initComparisons() {
  var x, i;
  // x is top layer image div
  x = document.getElementsByClassName("img-comp-overlay");
  for (i = 0; i < x.length; i++) {
    compareImages(x[i]);
  }
  function compareImages(img) {
    var slider, clicked = 0, w, h;
    /*get the width and height of the img element*/
    w = img.offsetWidth;
    h = img.offsetHeight;
    console.log(w, h);
    /*set the width of the img element to 50%:*/
    img.style.width = (w / 2) + "px";
    /*create slider:*/
    slider = document.createElement("DIV");
    slider.setAttribute("class", "img-comp-slider");
    /*insert slider*/
    img.parentElement.insertBefore(slider, img);
    /*position the slider in the middle:*/
    slider.style.top = (h / 2) - (slider.offsetHeight / 2) + "px";
    slider.style.left = (w / 2) - (slider.offsetWidth / 2) + "px";
    /*execute a function when the mouse button is pressed:*/
    slider.addEventListener("mousedown", slideReady);
    /*and another function when the mouse button is released:*/
    window.addEventListener("mouseup", slideFinish);
    /*or touched (for touch screens:*/
    slider.addEventListener("touchstart", slideReady);
    /*and released (for touch screens:*/
    window.addEventListener("touchstop", slideFinish);
    function slideReady(e) {
      /*prevent any other actions that may occur when moving over the image:*/
      e.preventDefault();
      /*the slider is now clicked and ready to move:*/
      clicked = 1;
      /*execute a function when the slider is moved:*/
      window.addEventListener("mousemove", slideMove);
      window.addEventListener("touchmove", slideMove);
    }
    function slideFinish() {
      /*the slider is no longer clicked:*/
      clicked = 0;
    }
    function slideMove(e) {
      var pos;
      /*if the slider is no longer clicked, exit this function:*/
      if (clicked === 0) return false;
      /*get the cursor's x position:*/
      pos = getCursorPos(e)
      /*prevent the slider from being positioned outside the image:*/
      if (pos < 0) pos = 0;
      if (pos > w) pos = w;
      /*execute a function that will resize the overlay image according to the cursor:*/
      slide(pos);
    }
    function getCursorPos(e) {
      var a, x = 0;
      e = e || window.event;
      /*get the x positions of the image:*/
      a = img.getBoundingClientRect();
      /*calculate the cursor's x coordinate, relative to the image:*/
      x = e.pageX - a.left;
      /*consider any page scrolling:*/
      x = x - window.pageXOffset;
      return x;
    }
    function slide(x) {
      /*resize the image:*/
      img.style.width = x + "px";
      /*position the slider:*/
      slider.style.left = img.offsetWidth - (slider.offsetWidth / 2) + "px";
    }
  }
}

const IndexPage = () => {

  useEffect(() => {
    initComparisons();
    // need to figure out how to keep slider position after changing languages
  
  });

  return (
    <>
      <Jumbotron fluid >
      <iframe title="comparison of landscape" frameBorder="0" className="juxtapose" width="100%" height="1000 px" src="https://cdn.knightlab.com/libs/juxtapose/latest/embed/index.html?uid=5694d2b0-21e8-11ea-b9b8-0edaf8f81e27"></iframe>
    
        {/* <Container fluid width="100%">
        <div className="img-comp-container">
          <div className="img-comp-img">
            <img alt="2019 vista" src={vista1} height="100%" width="100%"/>
          </div>
          <div className="img-comp-img img-comp-overlay">
            <img alt="1996 vista" src={vista2}  width="100%" height="1711" />
          </div>
        </div>
        </Container> */}
      </Jumbotron>
     
    </>
  )
};

const customProps = {
  localeKey: 'home',
};

export default withLayout(customProps)(IndexPage);
